<template>
  <div class="SponsorManagement" style=" margin: -15px -15px;">
    <!--  SponsorManagement 直播主办方管理-->
    <div class="SponsorManagement-top">
      <div class="SponsorManagement-top-input">
        <div>年份:</div>
        <a-input class="input" v-model="nameTop" placeholder="请输入 "/>
      </div>
      <div class="SponsorManagement-top-input">
        <span>状态：</span>
        <a-select
            style="width: 150px"
            class="input"
            @change="typeChange"
            v-model="stateTop"
            placeholder="请选择">
          <a-select-option
              v-for="type in stateTypeList"
              :value="type.state">
            {{type.title}}
          </a-select-option>
        </a-select>
      </div>
      <div class="SponsorManagement-top-input">
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div>
      <div style="width: 98%;margin: auto">
        <a-button @click="openModel" style="margin-top: 20px;margin-bottom: 20px" type="primary" >新增</a-button>
        <a-table
            :pagination="false"
            :columns="columns"
            :data-source="data"
            :row-key="record => record.id">
          <span slot="action" slot-scope="text, record">
            <a @click="routerBtn">合作计划</a>
            <a style="margin-left: 8px" @click="openRest">修改</a>
          </span>
          <span slot="state" slot-scope="text, record">
            <a-switch v-model="record.state === 1?true:false" checked-children="正常" un-checked-children="关闭" @click="listSwitch(record.state,record)" default-checked />
          </span>
        </a-table>
        <!--          分页-->
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                show-quick-jumper
                :current="page_no"
                @change="listChange"
                :total="listCount" />
          </div>
        </div>
      </div>
    </div>
<!--    对话框---------------------------------->
    <a-drawer
        :title="drawerTitle"
        :width="550"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="closeBtn"
    >
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item  label="主办方logo" prop="coverUrl">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="coverFoemId"
                  style="width: 100px;height: 100px;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="uplistCover"
                  ref="Thumbnail" />
            </div>
            <div
                class="upload_image">
              <img
                  v-if="form.coverUrl"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="form.coverUrl"
              />
            </div>
          </div>
          <div style="color: #a1a1a1">注:尺寸 200*200，大小不超过500kb</div>
        </a-form-model-item>
        <a-form-model-item  label="省份/城市"  prop="province">
          <a-cascader
              style="width: 300px"
              v-model="form.province"
              :options="provincePtions"
              placeholder="请选择"
              @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item  label="主办方名称" prop="name">
          <a-input v-model="form.name" style="width: 300px" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item  label="联系人" prop="person">
          <a-input v-model="form.person" style="width: 300px" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item  label="联系电话" prop="tel">
          <a-input v-model="form.tel" style="width: 300px" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item  label="业务负责人" prop="principal">
          <a-select style="width: 300px" placeholder="请选择" v-model="form.principal" >
            <a-select-option
                v-for="item in principalList"
                :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="closeBtn">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit">
          确认
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import City from "@/assets/city.json";

export default {
name: "SponsorManagement",
  created() {
  this.getPCH()
    this.$store.dispatch('setManageHeaderTitle',"直播主办方管理")
  },
  data(){
    return{
      nameTop:'',
      stateTop:'',
      stateTypeList:[
        {
          title:'全部',
          state:''
        },{
          title:'正常',
          state:'MIX_SET_TOPIC'
        },{
          title:'关闭',
          state:'VOD_SINGLE'
        },
      ],
      columns:[
        {
          title: '名称',
          dataIndex: 'title',
          key: 'title',
          width:'30%',
          scopedSlots: { customRender: 'name' },
          ellipsis: true,
        },{
          title: '联系人',
          dataIndex: 'expertName',
          key: 'expertName',
          // scopedSlots: { customRender: 'expertName' },
          width:'15%',
        },{
          title: '联系电话',
          dataIndex: 'tel',
          key: 'tel',
          width:'15%',
        },{
          title: '业务负责人',
          dataIndex: 'person',
          key: 'person',
          width:'15%',
        },{
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          ellipsis: true,
          width:'15%',
        },{
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          width:'10%',
          scopedSlots: { customRender: 'state' },
        },{
          title: '操作',
          key: 'action',
          width:'15%',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data:[
        {
          id:1,
          title:'1',
          expertName:'1',
          tel:'1',
          person:'1',
          createdTime:'1',
          state:1,
        }
      ],
      page_no:1,
      listCount:0,
      visible:false,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      form:{
        coverUrl:'',
        province:[],
        name:'',
        person:'',
        tel:'',
        principal:undefined,
      },

      rules: {
        coverUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
        province: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        person: [{ required: true, message: '请输入', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入', trigger: 'blur' },{ min: 11, max: 11, message: '手机号码为11位', trigger: 'blur' },],
        principal: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      drawerTitle:'新增',
      City:City,
      provincePtions:[],
      principalList:[
        {
          value:'',
          name:1111,
        }
      ]
    }
  },
  methods:{
    //获取省份城市医院
    getPCH(){
      let province = []
      let hospitalName = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.provincePtions = province
    },
    provinceChange(value) {
      console.log(value)
    },
    lookBtn(){

    },
    restBtn(){

    },
    typeChange(){

    },
    closeBtn(){
      this.visible = false
    },
    onSubmit(){
      this.visible = false
    },
    openModel(){
      this.drawerTitle = '新增'
      this.visible = true
    },
    openRest(){
      this.drawerTitle = '修改'
      this.visible = true
    },
    routerBtn(){
      this.$router.push({path:"/cooperationPlan"})
    },
    listSwitch(isOpen){

    },
    listChange(pageNo){
      this.page_no = pageNo
    },
    async uplistCover() {
      let inputDOM = this.$refs.Thumbnail.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.form.coverUrl = image.data.url
          this.$message.success("上传成功")
          document.getElementById('coverFoemId').value = null;
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
    },
  }
}
</script>

<style scoped lang="scss">
.SponsorManagement {
  height: 100%;
  .SponsorManagement-top {
    width: 100%;
    height: 85px;
    background-color: #fff;
    border-bottom: 15px solid #F0F2F5;
    display: flex;
    //justify-content: space-between;
    .SponsorManagement-top-input {
      display: flex;
      //justify-content: center;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
      padding-left: 20px;
      .input {
        width: 200px;
        margin-left: 10px;
      }
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 110px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>